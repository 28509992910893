<template>
  <v-row justify="center">
    <v-dialog v-model="shower" scrollable persistent max-width="700px" >
      <v-card >
        <v-card-title>{{ $t("mutideletedocinboxoutbox.listofdoc") }}<v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35">delete</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <br />
           <div class="text-center"><v-chip class="pt-4 pl-2 ma-2"><p ><v-icon :color="color.theme"> folder </v-icon><b> {{ $t("mutideletedocinboxoutbox.fileselect") }} {{ count_item }} {{ count_item > 1 ? $t("mutideletedocinboxoutbox.itemmutiinout") : $t("mutideletedocinboxoutbox.itemsmutiinout") }}</b></p></v-chip>
           <p v-if="check_delete === true">{{ $t("mutideletedocinboxoutbox.deletedoc") }} {{count_deleting}} / {{countshowdelete}} {{ countshowdelete > 1 ? $t("mutideletedocinboxoutbox.itemmutiinout") : $t("mutideletedocinboxoutbox.itemsmutiinout") }}</p>
          <!-- <v-chip class="pt-4 pl-2 ma-2"><p ><v-icon color="green"> mdi-delete</v-icon><b> มีสิทธิ์ลบ {{ countshowdelete }} ไฟล์</b></p></v-chip> -->
          <!-- <v-chip class="pt-4 pl-2 ma-2"><p ><v-icon color="red"> mdi-delete-off </v-icon><b> {{ countshowcannotdelete }} {{$t('dialogmultidelete.title2')}}</b></p></v-chip> -->
          </div> 
          <!-- class="modal" -->
          <div>
          <v-list class="v-list-items" >
            <!--โชว์ไฟล์สามารถลบได้ -->
            <v-list-item v-for="item in showdelete" :key="item.title">
              <v-list-item-action @mouseover="item.show_name = true" @mouseleave="item.show_name = false">
                <v-icon large color="primary">delete</v-icon>
              </v-list-item-action>
              <v-badge
                :value="item.show_name"
                color="grey"
                :content="item.file_name"
                
                transition="slide-y-transition"
              ></v-badge>
              <v-list-item-content>
                 
                <v-list-item-title @mouseover="item.show_name = true" @mouseleave="item.show_name = false">
                  {{ item.file_name }}</v-list-item-title
                >
                <v-list-item-subtitle>
                  <v-progress-linear
                    v-if="check_show_progress === true && item.file_value !== 100 && item.file_value !== 500"
                    :value="item.file_value"
                    color="light-blue"
                  ></v-progress-linear>
                </v-list-item-subtitle>
              </v-list-item-content>
              <!-- โชว์ว่ามีสิทธิ์ลบหรือไม่-->
              <!-- <v-list-item-action v-if="check_show_progress === false">
                <v-chip
                  class="ma-2 font-weight-bold"
                  :color="item.permission_delete === true ? 'light-blue' : 'red'"
                  label
                  outlined
                >
                 {{ item.permission_delete === true ? $t('dialogmultidelete.canbedelete') : $t('dialogmultidelete.cannotdelete') }}
                </v-chip>
              </v-list-item-action> -->
              <!-- โชว์สถานะการลบว่าลบสำเร็จหรือไม่ -->
              <v-list-item-action >
                <v-chip v-if="item.file_value !== 500" class="ma-2 font-weight-bold" :color="item.file_status === 'มีสิทธิ์ลบไฟล์' ? 'primary' : item.file_status === 'กำลังลบไฟล์' ? color.theme : item.file_status === 'ลบไฟล์สำเร็จ' ? 'green' : item.file_status === 'ไม่สามารถลบไฟล์นี้ได้' ? 'red' : '#F37958' " label outlined>
                  {{item.file_status === 'มีสิทธิ์ลบไฟล์' ? $t('mutideletedocinboxoutbox.permissiongranted') : item.file_status === 'กำลังลบไฟล์' ? $t('mutideletedocinboxoutbox.deletedoc') : item.file_status === 'ลบไฟล์สำเร็จ' ? $t('mutideletedocinboxoutbox.deletedocsuccess') : item.file_status === 'ไม่สามารถลบไฟล์นี้ได้' ? $t('mutideletedocinboxoutbox.permissiondenied') : $t('mutideletedocinboxoutbox.permissiondenied') }}
                </v-chip>
                <v-chip v-else class="ma-2 font-weight-bold" color="red" label outlined>
                  {{ item.file_status }}
                </v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- ปุ่มก่อนกดยืนยันที่จะลบหรือกดปิดdialog -->
          <v-btn
            v-if="check_show_progress === false && check_show_button === false"
            color="red"
            outlined
            @click="$emit('closedialog'),$emit('closebuttonmulti')"
            >{{ $t("changeName.close") }}</v-btn
          >
          <v-btn
            :color="color.theme"
            v-if="check_show_progress === false && check_show_button === false"
            :dark="color.darkTheme"
            @click="fn_multidelete(), (check_show_button = true), (check_show_progress = true), (check_delete = true), (check_dis_button = true)"
            >{{$t('dialogmultidelete.buttondelete')}}</v-btn
          >

          <v-btn color="red" :dark="color.darkTheme" 
          v-if="check_show_progress === true && check_dis_button=== true" 
          @click="cacelDelete()"> {{ $t('dialogmultidelete.canceldelete') }}</v-btn>

          <!-- ลบเสร็จจะโชว์ปุ่มนี้เพื่อกดปิด-->
          <v-btn color="red" :disabled="check_dis_button" v-if="check_show_button === true" outlined 
          @click="$emit('closedialogreload'),$emit('closebuttonmulti'),(check_delete = false)">{{$t('dialogmultidelete.buttonclose')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import  { CancelToken } from 'axios';

export default {
  mixins: [validationMixin],
  validations: {
    filename: { required },
  },
  props: ["show", "filedata",],
  data: function() {
    return {
      cancelSource: "", // New Variable
      checkcancel: false,
      check_dis_button:true,
      check_show_progress: false,
      check_show_button: false,
      showdelete: [],
      showcannotdelete:[],
      count_item: 0,
      countshowdelete:0,
      countshowcannotdelete:0,
      check_delete:false,
      count_deleting:0,
      

    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
          console.log("filedata",this.filedata);
        }
        return this.show;
      },
      set(value) {
        if (!value) {
        }
      },
    },
  },
  watch: {
    show: function(val) {
      if (val === true) {
        this.$emit("closeDrag");
        this.fn_loaddata();
        this.check_show_button = false;
        console.log(this.filedata);
      } else {
        this.$emit("openDrag"); 
        this.check_show_progress = false;
        this.showdelete = [];
        this.showcannotdelete = [];
        this.count_item = 0;
      }
    },
  },
  methods: {
    fn_loaddata() {
      //เช็คว่าข้อมูลที่เลือกมีสิทธ์ลบหรือไม่ ถ้ามีก็จะให้ยิง api ลบได้เลย ถ้าไม่มีจะอัปเดต status ที่โชว์ว่าไม่มีสิทธิ์ลบไฟล์นั้น
      for (let i = 0; i < this.filedata.length; i++) {
        this.count_item++;
        console.log("++++",this.filedata[i]);
        let datadelete = {};
        datadelete["file_name"] = this.filedata[i]["filename"];
        datadelete["file_id"] = this.filedata[i]["inbox_id"];
        datadelete["file_icon"] = this.filedata[i]["file_icon"];
        datadelete["file_status"] = "มีสิทธิ์ลบไฟล์";
        datadelete["file_value"] = 0;
        datadelete["show_name"] = false;
          this.showdelete.push(datadelete);
      
      }
      this.countshowdelete = this.showdelete.length;
      this.count_deleting = 0;
      console.log("showdelete",this.showdelete);
    },
    // //ฟังก์ชั่นวนลบไฟล์
    async fn_multidelete() {
      if(this.$router.app["_route"]["name"] === "myoutbox_sourcesystem"){
        
        // ## Cancel Token 
        this.cancelSource = CancelToken.source()

        for (let i = 0; i < this.filedata.length; i++) {
          let check_cancel
          let objIndex = this.showdelete.findIndex((obj) => obj.file_id === this.filedata[i].inbox_id);
          //เช็คเพื่อนวนส่งข้อมูลเฉพาะไฟล์ที่มีสิทธิ์ลบ
            this.showdelete[objIndex]["file_status"] = "กำลังลบไฟล์";
            let payload = {
              outbox_id: [this.filedata[i]["inbox_id"]],
              account_id: this.dataAccountId
            };
            console.log("payload",payload);
            await this.fn_delete(payload, this.filedata[i].inbox_id)
            .then(async (res) => {
              if(res.status === "delete success"){
                this.count_deleting++;
                console.log("yess");
              }else{
                console.log("nooo");
              }
            })
            .catch((err)=>{
              console.log("ERR", err)
              check_cancel = true
              this.showdelete[i]["file_status"] = this.$t("mutideletedocinboxoutbox.canceldelete");
              this.showdelete[i]["file_value"] = 500;
            })

            if(check_cancel){
              this.check_dis_button = false;
              break;
            }
          if(this.filedata.length - i === 1){
            this.check_dis_button = false;
        }
        let itemElememt = Array.from(document.getElementsByClassName('v-list-items')[0].children) 
        this.scrollToBottom_v2(itemElememt[i])
        //this.scrollToBottom();
        }
      }else{
        // ## Cancel Token 
        this.cancelSource = CancelToken.source()
        
        for (let i = 0; i < this.filedata.length; i++) {
           let check_cancel
          let objIndex = this.showdelete.findIndex((obj) => obj.file_id === this.filedata[i].inbox_id);
          //เช็คเพื่อนวนส่งข้อมูลเฉพาะไฟล์ที่มีสิทธิ์ลบ
            this.showdelete[objIndex]["file_status"] = "กำลังลบไฟล์";
            let payload = {
              inbox_id: [this.filedata[i]["inbox_id"]],
              account_id: this.dataAccountId
            };
            console.log("payload",payload);
            await this.fn_delete(payload, this.filedata[i].inbox_id)
            .then(async (res) => {
              if(res.status === "delete success"){
                this.count_deleting++;
                console.log("yess");
              }else{
             
                console.log("nooo");
              }
            })
            .catch((err)=>{
              check_cancel = true
              this.showdelete[i]["file_status"] = this.$t("mutideletedocinboxoutbox.canceldelete");
              this.showdelete[i]["file_value"] = 500;
            })

            if(check_cancel){
              this.check_dis_button = false;
              break;
            }

            if(this.filedata.length - i === 1){
              this.check_dis_button = false;
            }

            let itemElememt = Array.from(document.getElementsByClassName('v-list-items')[0].children)[i]
            this.scrollToBottom_v2(itemElememt)
            // if(this.filedata.length - i > 5 ){
              // this.scrollToBottom();
            // }
            
        }
      }
    },
    scrollToBottom() {
          var containers = document.querySelector(".scroll");
          var scrollHeight = containers.scrollHeight;
          containers.scrollTop = containers.scrollTop + 70
    },
    

    scrollToBottom_v2 (item) {
      item.scrollIntoView({block: "start", inline: "nearest", behavior: 'smooth' })
    },

    // //ลบไฟล์/โฟลเดอร์
    async fn_delete(payload, file_id) {
      if(this.$router.app["_route"]["name"] === "myoutbox_sourcesystem"){
        console.log("out");
        let objIndex = this.showdelete.findIndex((obj) => obj.file_id === file_id);
        let auth = await gbfGenerate.generateToken();
        let result = await this.axios({
          method: "POST",
          url: process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/delete_outbox",
          data: payload,
          headers: { Authorization: auth.code },
          cancelToken: this.cancelSource.token,

          onUploadProgress: (e) => {
            if ((e.loaded / e.total) * 100 >= 95) {
              this.showdelete[objIndex]["file_value"] = 95;
            } else {
              this.showdelete[objIndex]["file_value"] = parseInt((e.loaded / e.total) * 100);
            }
          },
        });
        return new Promise((resolve, reject) => {
          try {
            if (result.data.status === "OK") {
              this.showdelete[objIndex]["file_status"] = "ลบไฟล์สำเร็จ";
              this.showdelete[objIndex]["file_value"] = 100;
              setTimeout(() => {
                    resolve({ status: "delete success", msg: "OK", code: "" });
                      }, 500);
              
            } else {
              this.showdelete[objIndex]["file_status"] = "ไม่สามารถลบไฟล์นี้ได้";
              this.showdelete[objIndex]["file_value"] = 500;
              setTimeout(() => {
                    resolve({ status: "delete denied", msg: ": ", code: "" });
                      }, 500);
              
            }
          } catch (err) {
            setTimeout(() => {
                    resolve({ status: "delete denied", msg: ": " + err, code: "" });
                      }, 500);
            this.showdelete[objIndex]["file_status"] = "ไม่สามารถลบไฟล์นี้ได้";
            this.showdelete[objIndex]["file_value"] = 500;
          }
        });
      }else{
        console.log("in");
        let objIndex = this.showdelete.findIndex((obj) => obj.file_id === file_id);
         let auth = await gbfGenerate.generateToken();
        let result = await this.axios({
          method: "POST",
          url: process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/delete_inbox",
          data: payload,
          headers: { Authorization: auth.code },
          cancelToken: this.cancelSource.token,

          onUploadProgress: (e) => {
            if ((e.loaded / e.total) * 100 >= 95) {
              this.showdelete[objIndex]["file_value"] = 95;
            } else {
              this.showdelete[objIndex]["file_value"] = parseInt((e.loaded / e.total) * 100);
            }
          },
        });
        return new Promise((resolve, reject) => {
          try {
            if (result.data.status === "OK") {
              this.showdelete[objIndex]["file_status"] = "ลบไฟล์สำเร็จ";
              this.showdelete[objIndex]["file_value"] = 100;
              setTimeout(() => {
                    resolve({ status: "delete success", msg: "OK", code: "" });
                      }, 500);
              
            } else {
              this.showdelete[objIndex]["file_status"] = "ไม่สามารถลบไฟล์นี้ได้";
              this.showdelete[objIndex]["file_value"] = 500;
              setTimeout(() => {
                    resolve({ status: "delete denied", msg: ": ", code: "" });
                      }, 500);
              
            }
          } catch (err) {
            setTimeout(() => {
                    resolve({ status: "delete denied", msg: ": " + err, code: "" });
                      }, 500);
            this.showdelete[objIndex]["file_status"] = "ไม่สามารถลบไฟล์นี้ได้";
            this.showdelete[objIndex]["file_value"] = 500;
          }
        });
      }
    },

    // ## CANCEL DELETE
    async cacelDelete () {
      this.cancelSource.cancel()
    }

  },
};
</script>
<style>
  /* .scroll{
	width: 670px;
	max-height: 500px;
	overflow: scroll;
	background: lightgrey;
	margin-bottom: 20px;
} */
</style>
